<template>
  <div         class="s3m-file_upload">
    <div v-if="currentFile">
      <div>
        <v-progress-linear
            v-model="progress"
            color="light-blue"
            height="25"
            reactive
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    </div>

    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input
            show-size
            v-model="currentFile"
            label="Dateiauswahl"
            @change="fileChanged"
        ></v-file-input>
      </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="message" border="left" color="blue-grey" dark v-html="message"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";

export default {
  name: "FileUpload",
  props: {
    description: {
      type: String,
      required: false
    },
    unzipContent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: "",
      fileMeta: '',
    };
  },
  methods: {
    fileChanged() {
      this.progress = 0;
    },
    async upload() {
      if (!this.currentFile) {
        this.message = "Bitte wählen sie eine Datei aus!";
        return;
      }

      this.message = "";
      this.$emit('started');
      APIService.upload(this.currentFile, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }, this.unzipContent).then((response) => response.data).then(fileMeta => {
        this.message="Die Datei wurde erfolgreich übertragen"
        this.fileMeta = fileMeta
        this.currentFile = null
        this.$emit('uploaded', fileMeta)
      }).catch((error) => {
        this.progress = 0;
        this.message = `Die Datei konnte nicht übertragen werden:<br> ${error.response?.data?.message}`;
        this.currentFile = undefined;
      });
    }
  },
  mounted() {
    //  APIService.getFiles().then(response => {
    // this.fileInfos = response.data;
    //  });
  }
}
</script>

<style scoped>

</style>
